.has-search .form-control {
    display: flex;
    padding-left: 2.375rem;
    /* padding-top: 2.375rem; */
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.cus-icon{
    background-image: url('../assets/images/search.png');
    background-repeat: no-repeat;
    background-position: 8px 5px;
    background-size: 25px;
}